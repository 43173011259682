.evidence-popup {
  position: fixed;
  z-index: 999;
  height: 100%;
  width: 50%;
  background: #fff;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: #000 2px solid;
  padding: 12px;
  overflow: scroll;
  box-sizing: border-box;

  img {
    width: 100%;
  }
}
