.camera {
  position: relative;

  .blur-overlay {
    backdrop-filter: blur(10px);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .vjs-custom-button {
    position: absolute;
    width: 20%;
    height: 20%;
    background: #fff;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;

    .vjs-icon-placeholder {
      display: none;
    }

    .vjs-control-text {
      clip: unset;
      width: auto;
      height: auto;
      color: #000;
    }
  }
}

/* Disable display of video controls */
.vjs-live-mode-controls.video-js > video::-webkit-media-controls {
  display: none;
}

.vjs-live-mode-controls.video-js > video::-webkit-media-controls-enclosure {
  display: none;
}

