.episode-card {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 15px;

  img {
    margin-right: 15px;
  }
}
